<template>
  <ValidationProvider
    :name="$t('invoice.transfer.type')"
    :rules="required ? 'required' : ''"
    slim
    vid="invoicetransfertype"
  >
    <template #default="{ errors, valid }">
      <v-select
        v-model="transferType"
        :error-messages="errors"
        :items="items"
        :label="$t('invoice.transfer.type')"
        :menu-props="{ offsetY: true }"
        :success="valid"
        dense
        hide-details
        outlined
        v-bind="$attrs"
        @change="onChange"
      />
    </template>
  </ValidationProvider>
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue } from "vue-property-decorator";

@Component
export default class InvoiceTransferTypeSelect extends Vue {
  @VModel({ type: Number }) transferType!: number;
  @Prop(Boolean) readonly required!: boolean;

  get items() {
    return [
      { value: 1, text: this.$t("sales") },
      { value: 2, text: this.$t("internal.transfers") },
    ];
  }

  onChange(sValue: number) {
    this.$emit("change", sValue);
  }
}
</script>
