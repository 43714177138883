<template>
  <ValidationProvider
    :name="$t('payment.terms')"
    :rules="sRules"
    vid="payment_term_id"
    slim
  >
    <template #default="{ errors, valid }">
      <v-select
        :value="paymentTermId"
        :error-messages="errors"
        :success="required ? valid : undefined"
        :label="hideLabel ? undefined : $t('payment.terms')"
        :menu-props="{ offsetY: true }"
        :items="items"
        :disabled="disabled"
        item-text="name"
        item-value="id"
        outlined
        dense
        hide-details
        @change="onSelect"
      />
    </template>
  </ValidationProvider>
</template>

<script lang="ts">
import { PaymentTerm, PaymentTerms } from "@planetadeleste/vue-mc-gw";
import type { PaymentTermData } from "@planetadeleste/vue-mc-gw/src/types";
import { forEach } from "lodash";
import { Component, Prop, VModel, Vue, Watch } from "vue-property-decorator";

@Component
export default class PaymentTermsSelect extends Vue {
  @VModel({ type: [String, Number] }) paymentTermId!: number;
  @Prop(Boolean) readonly required!: boolean;
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop(Boolean) readonly hideLabel!: boolean;
  @Prop(Array) readonly paymentTerms!: Partial<PaymentTermData>[];

  obCollection: PaymentTerms = new PaymentTerms();

  @Watch("paymentTerms")
  onChangePaymentTerms() {
    this.load();
  }

  mounted() {
    this.$nextTick(this.load);
  }

  onSelect(iValue: number) {
    if (this.disabled) {
      return;
    }

    this.$emit("input", iValue);
    this.$emit("change", this.obCollection.find({ id: iValue }));
  }

  async load() {
    this.obCollection.clear();

    if (this.paymentTerms && this.paymentTerms.length) {
      forEach(this.paymentTerms, (obData) => {
        this.obCollection.add(new PaymentTerm(obData));
      });
    } else {
      await this.obCollection.list();
    }

    this.$emit("load", this.obCollection.models);

    const obModel = this.paymentTermId
      ? this.obCollection.find({ id: this.paymentTermId })
      : this.obCollection.first();
    if (obModel) {
      this.onSelect(obModel.id);
    }
  }

  get sRules() {
    let sRules = "";
    if (this.required && !sRules.includes("required")) {
      sRules = sRules.length ? `required|${sRules}` : "required";
    }
    return sRules;
  }

  get items() {
    return this.obCollection && this.obCollection.length
      ? this.obCollection.getModelList()
      : [];
  }
}
</script>
