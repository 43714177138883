import type {
  InvoiceMovementTypeData,
  InvoiceTypeData,
} from "@planetadeleste/vue-mc-gw";
import { castArray, filter, get, intersection, isUndefined, map } from "lodash";
import { number } from "mathjs";
import { MovementTypeModule } from "@/store/movementtype";

export class InvoiceMovementTypeHelper {
  private _invoiceTypeCode: number | number[] | null = null;
  private _movementTypeCode: number | number[] | null = null;

  get arItemList(): Partial<InvoiceMovementTypeData>[] {
    return MovementTypeModule.items;
  }

  get items(): Partial<InvoiceMovementTypeData>[] {
    if (this.arCodeList.length) {
      return filter(this.arItemList, (obItem) =>
        this.arCodeList.includes(number(obItem.code))
      );
    }

    if (this.arInvoiceTypeCodeList.length) {
      return filter(this.arItemList, (obItem) => {
        const arInvoiceType: InvoiceTypeData[] = get(
          obItem,
          "invoice_types",
          []
        );
        const arCodeList: number[] = map(arInvoiceType, (obItem) =>
          number(obItem.code)
        );

        return (
          arCodeList.length > 0 &&
          intersection(this.arInvoiceTypeCodeList, arCodeList).length > 0
        );
      });
    }

    return this.arItemList;
  }

  get arInvoiceTypeCodeList(): number[] {
    return this._invoiceTypeCode ? castArray(this._invoiceTypeCode) : [];
  }

  get arCodeList(): number[] {
    return this._movementTypeCode ? castArray(this._movementTypeCode) : [];
  }

  async load() {
    if (this.arItemList.length) {
      return;
    }

    await MovementTypeModule.load();
  }

  setInvoiceTypeCode(iValue: number | null) {
    this._invoiceTypeCode = iValue;
  }

  setCode(iValue: number | null) {
    this._movementTypeCode = iValue;
  }
}

export default async function useInvoiceMovementType(
  sCode?: number | null | undefined,
  sInvoiceTypeCode?: number | null
) {
  const obMovementType = new InvoiceMovementTypeHelper();

  if (!isUndefined(sCode)) {
    obMovementType.setCode(sCode);
  }

  if (!isUndefined(sInvoiceTypeCode)) {
    obMovementType.setInvoiceTypeCode(sInvoiceTypeCode);
  }

  await obMovementType.load();

  return { items: obMovementType.items, obj: obMovementType };
}
