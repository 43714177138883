<template>
  <ValidationProvider
    :name="$t('invoice.group')"
    :rules="required ? 'required' : ''"
    slim
    vid="invoicegroup"
  >
    <template #default="{ errors, valid }">
      <v-select
        v-model="invoiceGroupId"
        :error-messages="errors"
        :items="obCollection.getModelList()"
        :label="$t('invoice.group')"
        :loading="loading"
        :menu-props="{ offsetY: true }"
        :success="valid"
        dense
        item-text="name"
        item-value="id"
        outlined
        @change="onSelect"
      />
    </template>
  </ValidationProvider>
</template>

<script lang="ts">
import { InvoiceGroupCollection } from "@planetadeleste/vue-mc-gw";
import { Component, Prop, VModel, Vue } from "vue-property-decorator";

@Component
export default class InvoiceGroupSelect extends Vue {
  @VModel({ type: Number }) invoiceGroupId!: number;
  @Prop(Boolean) readonly required!: boolean;

  obCollection: InvoiceGroupCollection = new InvoiceGroupCollection();
  loading = false;

  mounted() {
    this.$nextTick(this.load);
  }

  async load() {
    this.loading = true;

    this.obCollection.clear();
    await this.obCollection.filterBy({ active: true }).list();

    this.loading = false;
  }

  onSelect(iInvoiceGroupId: number): void {
    const obInvoiceGroup = this.obCollection.find({ id: iInvoiceGroupId });
    if (obInvoiceGroup) {
      this.$emit("change", obInvoiceGroup);
    }
  }
}
</script>
